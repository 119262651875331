import React from "react";

import Layout from "../layouts/DocsLayout";

export default function Home() {
  return (
    <Layout title="Getting Started">
      <p>
        Welcome to the documentation website for the Nexus211 platform. You’ll
        find information that can help you understand:
      </p>
      <ul className="list-disc ml-5 leading-8 my-4">
        <li>What you can build with Nexus211</li>
        <li>How to build it</li>
        <li>How to get assistance</li>
      </ul>
      Use the navigation menu on the left to quickly jump to the sections of
      this website that are most relevant to your needs.
    </Layout>
  );
}
